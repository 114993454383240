import BasePlugin from '../BasePlugin'
import ImportBudgetIncomeAndExpensesFromScenarioCmp from './ImportBudgetIncomeAndExpensesFromScenarioCmp'

export default class ImportBudgetIncomeAndExpensesFromScenario extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getModel().id
    const h = this.context.$createElement
    this.context.$msgbox({
      title: 'Выберите файл для загрузки',
      message: h('p', { key: Math.random() }, [
        h(ImportBudgetIncomeAndExpensesFromScenarioCmp, { props: { model: scenarioId } })
      ]),
      showCancelButton: false,
      showConfirmButton: false
    })
  }
}
